import React, { useState, useEffect } from "react";
import { doc, getDoc, updateDoc, Timestamp } from "firebase/firestore";
import { firestore } from "../../firebase";
import Stripe from "stripe";
import Modal from "react-modal";

const stripe = Stripe(
  "sk_test_51OC6QGF698POdFrNomZgR5tNBvaWTSnrpzevHl1YXKIBfiB5qaU79YeV2GuCBBKZgdhKZdWapxKqqfa2l0ATsPlf00FW6qVCHg"
);

const db = firestore;

const Subscription = ({ currentBusinessID }) => {
  const [subscriptionInfo, setSubscriptionInfo] = useState({
    startDate: null,
    nextPayment: null,
    paymentAmount: null,
  });
  const [paymentHistory, setPaymentHistory] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [activeSubscription, setActiveSubscription] = useState(false);
  const [openNewAccountModal, setOpenNewAccountModal] = useState(false);
  const [openStartNewSubModal, setOpenStartNewSubModal] = useState(false);
  const [numActive, setNumActive] = useState(0);
  const [subscriptionUpated, setSubscriptionUpated] = useState(false);
  const [endSubscriptionModal, setEndSubscriptionModal] = useState(false);

  useEffect(() => {
    const effectFunction = async () => {
      const userInfo = doc(db, "businesses", currentBusinessID);
      const userInformation = await getDoc(userInfo);
      const userData = userInformation.data();
      const menus = userData.menus;

      const getDiscount = userData.discountCode ?? null;
      const stripeID = userData.stripeID ?? null;

      if (userData.activeSub !== undefined && userData.activeSub === true) {
        setActiveSubscription(true);
      } else {
        setActiveSubscription(false);
      }

      if (getDiscount && getDiscount.length > 0) {
        console.log(getDiscount);
        const discountInfo = doc(db, "discountCodes", getDiscount);
        const discountInformation = await getDoc(discountInfo);
        setDiscount(discountInformation.data());
      }

      let activeLocationsCount = 0;

      // Loop through each menu item
      for (const menu of menus) {
        // Assuming each menu has an identifier to get the specific locations collection
        const locationsRef = doc(db, "lokasjoner", menu);
        const querySnapshot = await getDoc(locationsRef);

        if (querySnapshot.data().active === true) {
          activeLocationsCount += 1; // Count active locations
        }
      }

      if (stripeID !== null) {
        const invoices = await stripe.invoices.list({
          customer: stripeID,
        });
        const subscription = await stripe.subscriptions.list({
          customer: stripeID,
          status: "active", // You can specify status as 'active', 'past_due', etc.
        });

        if (subscription.data[0] !== undefined) {
          await stripe.invoices
            .retrieveUpcoming({
              customer: stripeID,
            })
            .then((upcomingInvoice) => {
              const amountDue = upcomingInvoice.amount_due; // Amount in cents
              const amount = (amountDue / 100).toFixed(2); // Convert to whole number

              // Send this amount to the frontend to display to the user

              setSubscriptionInfo({
                startDate: subscription.data[0].start_date,
                nextPayment: subscription.data[0].current_period_end,
                paymentAmount: amount,
              });
              console.log(subscription.data[0]);
            })
            .catch((error) => {
              console.error(error);
            });
        }

        setPaymentHistory(invoices);
      }

      setNumActive(activeLocationsCount);
    };

    const isValidID =
      currentBusinessID &&
      typeof currentBusinessID === "string" &&
      currentBusinessID.length > 0;

    if (isValidID) {
      effectFunction();
    } else {
      console.log("Invalid business ID. Please check the ID or sign in.");
    }
  }, [currentBusinessID, subscriptionUpated]);

  const createNewSubscription = async (e) => {
    // e.preventDefault();
    const userDoc = doc(db, "businesses", currentBusinessID);
    const userInfo = await getDoc(userDoc);

    let stripeID = userInfo.data().stripeID ? userInfo.data().stripeID : null;

    if (stripeID === null) {
      setOpenNewAccountModal(true);
    } else {
      const getCustomer = await stripe.customers.retrieve(stripeID);
      console.log(getCustomer);

      if (getCustomer.invoice_settings.default_payment_method === null) {
        setOpenNewAccountModal(true);
      } else {
        setOpenStartNewSubModal(true);
      }
    }
  };

  const onStartNewSub = async () => {
    const userDoc = doc(db, "businesses", currentBusinessID);
    const userInfo = await getDoc(userDoc);

    let stripeID = userInfo.data().stripeID ? userInfo.data().stripeID : null;
    const discountPrice =
      discount && discount.date > Timestamp.now() ? discount.priceCode : null;
    const fullPrice = "price_1PiwutF698POdFrNhhBNRY0i"; //oppdatert til prod ID!

    try {
      let phases = [];

      // Check if the discount price is valid
      if (discountPrice) {
        // Add the discount phase
        phases.push({
          items: [{ price: discountPrice, quantity: numActive }],
          iterations: discount.runningMonths, // Number of months with discount price
        });
      }

      // Add the full price phase
      phases.push({
        items: [{ price: fullPrice, quantity: numActive }],
        iterations: 1, // Continue indefinitely with full price
      });

      // Create the subscription schedule with phases
      const subscriptionSchedule = await stripe.subscriptionSchedules.create({
        customer: stripeID,
        start_date: "now",
        end_behavior: "release",
        phases: phases,
      });

      console.log(subscriptionSchedule);
      await updateDoc(doc(db, "businesses", currentBusinessID), {
        activeSub: true,
      });

      userInfo.data().menus.forEach((element) => {
        const locationInfo = doc(db, "lokasjoner", element);
        updateDoc(locationInfo, {
          activeSub: true,
        });
      });

      setSubscriptionUpated(!subscriptionUpated);
    } catch (error) {
      console.error("Failed to create subscription schedule:", error);
    }
  };

  const onCancelSub = async () => {
    const userDoc = doc(db, "businesses", currentBusinessID);
    const userInfo = await getDoc(userDoc);

    let stripeID = userInfo.data().stripeID ? userInfo.data().stripeID : null;

    const subscription = await stripe.subscriptions.list({
      customer: stripeID,
      status: "active", // You can specify status as 'active', 'past_due', etc.
      discountCode: "",
    });

    if (subscription.data[0] !== undefined) {
      const cancelSubscription = await stripe.subscriptions.cancel(
        subscription.data[0].id
      );
      console.log(cancelSubscription);
      await updateDoc(doc(db, "businesses", currentBusinessID), {
        activeSub: false,
      });
      userInfo.data().menus.forEach((element) => {
        const locationInfo = doc(db, "lokasjoner", element);
        updateDoc(locationInfo, {
          activeSub: false,
        });
      });
      setSubscriptionUpated(!subscriptionUpated);
    }
  };

  const onCreateNewCustomer = async () => {
    const userDoc = doc(db, "businesses", currentBusinessID);
    const userInfo = await getDoc(userDoc);
    console.log("running");

    const email = userInfo.data().businessContactEmail;
    const businessName = userInfo.data().name;

    const customer = await stripe.customers.create({
      name: businessName,
      email: email,
    });

    if (customer.default_source === null) {
      const session = await stripe.billingPortal.sessions.create({
        customer: customer.id,
        return_url: "http://mapli.no/MyBusiness/subscription",
      });

      window.location.href = session.url;
    }

    await updateDoc(doc(db, "businesses", currentBusinessID), {
      stripeID: customer.id,
    });
  };

  return (
    <>
      <div className='editLocationPage'>
        <h1>Premium Abonnement</h1>
        <label style={{ color: activeSubscription ? "#52c78c" : "#f9615b" }}>
          {activeSubscription ? "Aktiv" : "Ikke aktiv"}
        </label>

        <div className='subscriptionPageContainer'>
          <div className='box'>
            <div className='boxInner' style={{ paddingBottom: 0 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontWeight: "bold",
                }}>
                <label>Abonnert siden:</label>
                <label>Betalingsperiode:</label>
                <label>Neste betaling:</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  color: "gray",
                }}>
                <label>
                  {activeSubscription && subscriptionInfo.startDate !== null
                    ? new Date(
                        subscriptionInfo.startDate * 1000
                      ).toLocaleDateString("nb-NO", {
                        day: "2-digit", // Ensure the day is two digits
                        month: "2-digit", // Ensure the month is two digits
                        year: "numeric", // Full year
                      })
                    : "-"}
                </label>
                <label>Månedlig</label>
                <label>{subscriptionInfo.paymentAmount} NOK</label>
              </div>
            </div>
            <div
              style={{
                marginLeft: 20,
                marginRight: 20,
                marginBottom: 20,
                flexDirection: "column",
              }}>
              <label style={{ color: "gray", fontSize: 14, paddingBottom: 0 }}>
                Pris beregnes ut fra antall aktive lokasjoner.
                <br />
                Kroner 150 * antall aktive lokasjoner
              </label>
            </div>
            {activeSubscription ? (
              <button
                className='universalButton'
                style={{
                  margin: "20px",
                  marginTop: 0,
                }}
                onClick={() => {
                  setEndSubscriptionModal(true);
                }}>
                Avslutt Abonnement
              </button>
            ) : (
              <button
                className='universalButtonRed'
                style={{
                  margin: "20px",
                  marginTop: 0,
                  width: "50%",
                }}
                onClick={createNewSubscription}>
                Start Abonnement
              </button>
            )}
            {discount !== null ? (
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  flexDirection: "column",
                }}>
                <label
                  title={discount.aboutCode}
                  style={{ color: "gray", fontSize: 14, paddingBottom: 0 }}>
                  Du har en aktiv rabattkode! Hold for å lese mer
                </label>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className='box'>
            <div className='boxInner2'>
              <label style={{ fontWeight: "bold" }}>
                Neste faktureringsdato
              </label>
              <label className='subscriptionInfoDate'>
                {activeSubscription && subscriptionInfo.nextPayment !== null
                  ? new Date(
                      subscriptionInfo.nextPayment * 1000
                    ).toLocaleDateString("nb-NO", {
                      day: "2-digit", // Ensure the day is two digits
                      month: "2-digit", // Ensure the month is two digits
                      year: "numeric", // Full year
                    })
                  : "-"}
              </label>
            </div>
          </div>
          <div className='box'>
            <div className='boxInner2'>
              <label style={{ fontWeight: "bold" }}>Aktive lokasjoner</label>
              <label className='subscriptionInfoDate'>{numActive}</label>
            </div>
          </div>
        </div>

        <div
          className='box'
          style={{ marginTop: "20px", padding: "20px", marginBottom: "20px" }}>
          <label style={{ fontWeight: "bold" }}>Historikk</label>
          <div className='historyTable'>
            <div className='historyTableHeader'>
              <label>Dato</label>
              <label>TransaksjonsID</label>
              <label>Sum</label>
              <label>Kvittering</label>
            </div>
            {paymentHistory !== null ? (
              paymentHistory.data.map((val, index) => (
                <div className='historyTableRow' key={index}>
                  <div>
                    {new Date(
                      val.status_transitions.paid_at * 1000
                    ).toLocaleDateString("nb-NO", {
                      day: "2-digit", // Ensure the day is two digits
                      month: "2-digit", // Ensure the month is two digits
                      year: "numeric", // Full year
                    })}
                  </div>
                  <div>{val.number}</div>
                  <div>{val.total / 100} NOK</div>
                  <div>
                    <a href={val.invoice_pdf} target='_blank'>
                      Last ned
                    </a>
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={openNewAccountModal}
        onRequestClose={() => {
          setOpenNewAccountModal(false);
        }}
        className='modalStyle'
        style={{
          content: {
            width: "17%",
          },
        }}>
        <div className='editActiveModal'>
          <label className='editPageLabel'>
            Før vi kan fortsette må du legge til en betalingsmetode hos
            betalingsleverandøren vår Stripe.{" "}
            <a
              href='/faq#stripe'
              className='locationNameText'
              style={{ textDecoration: "underline" }}>
              les mer
            </a>
          </label>
          <div className='editActiveModalButtonContainer'>
            <button
              onClick={() => (
                onCreateNewCustomer(), setOpenNewAccountModal(false)
              )}
              className='universalButtonRed'
              style={{
                width: "100%",
                margin: 0,
              }}>
              Gå til betalingsleverandør
            </button>
            <button
              onClick={() => {
                setOpenNewAccountModal(false);
              }}
              className='universalButton'
              style={{
                width: "70%",
                marginTop: 10,
                marginBottom: 0,
              }}>
              Nei, ta meg tilbake
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={openStartNewSubModal}
        onRequestClose={() => {
          setOpenStartNewSubModal(false);
        }}
        className='modalStyle'
        style={{
          content: {
            width: "17%",
          },
        }}>
        <div className='editActiveModal'>
          <label className='editPageLabel'>
            Er du sikker på at du vil starte et nytt aboonnement?
          </label>
          <div className='editActiveModalButtonContainer'>
            <button
              onClick={() => (onStartNewSub(), setOpenStartNewSubModal(false))}
              className='universalButtonRed'
              style={{
                width: "100%",
                margin: 0,
              }}>
              Start Abonnement
            </button>
            <button
              onClick={() => {
                setOpenStartNewSubModal(false);
              }}
              className='universalButton'
              style={{
                width: "70%",
                marginTop: 10,
                marginBottom: 0,
              }}>
              Nei, ta meg tilbake
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={endSubscriptionModal}
        onRequestClose={() => {
          setEndSubscriptionModal(false);
        }}
        className='modalStyle'
        style={{
          content: {
            width: "17%",
          },
        }}>
        <div className='editActiveModal'>
          <label className='editPageLabel'>
            Er du sikker på at du vil avslutte abonnementet og skjule alle
            lokasjonene fra Mapli?
          </label>
          <div className='editActiveModalButtonContainer'>
            <button
              onClick={() => (onCancelSub(), setEndSubscriptionModal(false))}
              className='universalButtonRed'
              style={{
                width: "100%",
                margin: 0,
              }}>
              Avslutt abonnement
            </button>
            <button
              onClick={() => {
                setEndSubscriptionModal(false);
              }}
              className='universalButton'
              style={{
                width: "70%",
                marginTop: 10,
                marginBottom: 0,
              }}>
              Nei, ta meg tilbake
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Subscription;
