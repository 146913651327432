import React, { useState, useEffect } from "react";
import "../../css/newBusinesspages.css";
import { getAuth, signOut } from "firebase/auth";

const auth = getAuth();

const SideMenu = ({ page, currentBusiness, user }) => {
  console.log(currentBusiness);

  return (
    <div className='sidepanelcontainer'>
      <div className='sidePanelMain'>
        <div
          style={{
            marginLeft: 50,
          }}>
          <h3>{!currentBusiness ? "Bedriftsnavn" : currentBusiness}</h3>
          <p>
            <a
              href='/MyBusiness/myLocations'
              style={{
                textDecoration: page === "myLocations" ? "underline" : "",
              }}>
              Mine lokasjoner
            </a>
          </p>
          {/* <p>
            <a
              href='/MyBusiness/subscription'
              style={{
                textDecoration: page === "subscription" ? "underline" : "",
              }}>
              Premium
            </a>
          </p> */}
          <p>
            <a
              href='/MyBusiness/events'
              style={{
                textDecoration: page === "events" ? "underline" : "",
              }}>
              Arrangementer
            </a>
          </p>
          <p>
            <a
              href='/MyBusiness/statsPage'
              style={{
                textDecoration: page === "statsPage" ? "underline" : "",
              }}>
              Statistikk
            </a>
          </p>
          <p>
            <a
              href='/MyBusiness/profile'
              style={{
                textDecoration: page === "profile" ? "underline" : "",
              }}>
              Profil
            </a>
          </p>
          <p>
            <a
              href='#'
              onClick={() => {
                auth.signOut();
              }}
              style={{
                textDecoration: page === "logOut" ? "underline" : "",
              }}>
              Logg ut
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
