import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import {
  doc,
  getDoc,
  setDoc,
  collection,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firestore } from "../../firebase";
import Modal from "react-modal";
import { v4 as uuidv4 } from "uuid";
import Stripe from "stripe";
import EditAbout from "./editLocationComponents/about";
import EditMenu from "./editLocationComponents/menu";
import EditReviews from "./editLocationComponents/reviews";

const stripe = Stripe(
  "sk_test_51OC6QGF698POdFrNomZgR5tNBvaWTSnrpzevHl1YXKIBfiB5qaU79YeV2GuCBBKZgdhKZdWapxKqqfa2l0ATsPlf00FW6qVCHg"
);

const auth = getAuth();
const db = firestore;
const storage = getStorage();

const EditMyLocation = ({ currentBusinessID }) => {
  const [regularOpeningHours, setRegularOpeningHours] = useState({
    mon: null,
    tue: null,
    wed: null,
    thu: null,
    fri: null,
    sat: null,
    sun: null,
  });
  const [regularClosingHours, setRegularClosingHours] = useState({
    mon: null,
    tue: null,
    wed: null,
    thu: null,
    fri: null,
    sat: null,
    sun: null,
  });
  const [newCustomOpeningHours, setNewCustomOpeningHours] = useState({
    date: null,
    open: null,
    close: null,
  });
  const [locationInformation, setLocationinformation] = useState(null);
  const [selectedEditMenu, setSelectedEditMenu] = useState("about");
  const [openSaveChangesModal, setOpenSaveChangesModal] = useState(false);
  const [openCancelChangesModal, setOpenCancelChangesModal] = useState(false);
  const [openActivateModal, setOpenActivateModal] = useState(false);
  const [newProfileImage, setNewProfileImage] = useState("");
  const [businessOwner, setBusinessOwner] = useState(false);
  const [textInformation, setTextInformation] = useState({
    name: "",
    tags: [],
    adress: "",
    description: "",
    websiteLink: "",
    bookingLink: "",
    phoneLink: "",
    tags_enUS: [],
    description_enUS: "",
    locationTags: [],
    locationTags_enUS: [],
  });
  const [fileImage, setFileImage] = useState({
    file1: "",
    file2: "",
    file3: "",
    file4: "",
  });
  const [fileName, setFileName] = useState({
    file1: "",
    file2: "",
    file3: "",
    file4: "",
  });
  const [menuList, setMenuList] = useState([]);
  const [newMenu, setNewMenu] = useState(false);
  const [menuListEnglish, setMenuListEnglish] = useState([]);
  const [newMenuEnglish, setNewMenuEnglish] = useState(false);
  const [googleBusinessDetails, setGoogleBusinessDetails] = useState(null);
  const [tripadvisorLink, setTripadvisorLink] = useState("");
  const [yelpLink, setYelpLink] = useState("");
  const [newProfileFile, setNewProfileFile] = useState(null);
  const [newProfileImagePreview, setNewProfileImagePreview] = useState("");
  const [savedChanges, setSavedChanges] = useState(false);
  const [editLanguage, setEditLanguage] = useState("norwegian");
  let { edit } = useParams();
  const navigate = useNavigate();

  const type = {
    restaurant: "Restaurant",
    activity: "Aktivitet",
    event: "Arrangement",
  };

  useEffect(() => {
    const getLocationInformation = async () => {
      const getLocation = doc(db, "lokasjoner", edit);
      const regularOpeningHoursRef = collection(
        db,
        "lokasjoner",
        edit,
        "regularOpeningHours"
      );
      const locationInfo = await getDoc(getLocation);

      if (locationInfo.data().owner === currentBusinessID) {
        setBusinessOwner(true);
      }

      const querySnapshot = await getDocs(regularOpeningHoursRef);
      const regularOpeningHoursOpeningArray = {};
      const regularOpeningHoursClosingArray = {};

      let locationtags = "";
      if (locationInfo.data().tags) {
        locationInfo.data().tags.forEach((item, index) => {
          if (index < locationInfo.data().tags.length - 1) {
            locationtags += item + ", ";
          } else {
            locationtags += item; // Do not add comma and space after the last element
          }
        });
      }

      setTextInformation((prevState) => ({
        ...prevState,
        name: locationInfo.data().locationName,
        tags: locationInfo.data().tags ? locationInfo.data().tags : [],
        tags_enUS: locationInfo.data().tagsLang
          ? locationInfo.data().tagsLang.en_US
            ? locationInfo.data().tagsLang.en_US
            : []
          : [],
        description_enUS: locationInfo.data().aboutBusinessLang
          ? locationInfo.data().aboutBusinessLang.en_US
            ? locationInfo.data().aboutBusinessLang.en_US
            : ""
          : "",
        adress: locationInfo.data().adress ? locationInfo.data().adress : "",
        description: locationInfo.data().aboutBusiness
          ? locationInfo.data().aboutBusiness
          : "",
        websiteLink: locationInfo.data().website
          ? locationInfo.data().website
          : "",
        bookingLink: locationInfo.data().bookingLink
          ? locationInfo.data().bookingLink
          : "",
        phoneLink: locationInfo.data().phoneNumber
          ? locationInfo.data().phoneNumber
          : "",
        locationTags: locationInfo.data().locationTags
          ? locationInfo.data().locationTags
          : [],
        locationTags_enUS: locationInfo.data().locationTagsLang
          ? locationInfo.data().locationTagsLang.en_US
            ? locationInfo.data().locationTagsLang.en_US
            : []
          : [],
      }));
      setYelpLink(locationInfo.data().yelpLink);
      setTripadvisorLink(locationInfo.data().tripadvisorLink);
      if (locationInfo.data().googleNumReviews) {
        setGoogleBusinessDetails({
          user_ratings_total: locationInfo.data().googleNumReviews,
          rating: locationInfo.data().googleRating,
          place_id: locationInfo.data().googleId,
          url: locationInfo.data().googleLink,
        });
      }
      setMenuList(locationInfo.data().menus);
      setMenuListEnglish(
        locationInfo.data().menuListLang
          ? locationInfo.data().menuListLang.en_US
            ? locationInfo.data().menuListLang.en_US
            : []
          : []
      );
      setNewProfileImage(locationInfo.data().mainProfileImage);

      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        regularOpeningHoursOpeningArray[doc.id] = docData.open;
        regularOpeningHoursClosingArray[doc.id] = docData.close;
      });

      if (!querySnapshot.empty) {
        setRegularOpeningHours(regularOpeningHoursOpeningArray);
        setRegularClosingHours(regularOpeningHoursClosingArray);
      }

      setLocationinformation(locationInfo.data());
      // set images:
      if (locationInfo.data().profileImages) {
        locationInfo.data().profileImages.forEach((x, index) => {
          const fileName = `file${index + 1}`; // This will create "file1", "file2", etc.
          setFileImage((prevData) => ({
            ...prevData,
            [fileName]: x, // Dynamically assign the property name and value
          }));
        });
      }
    };
    getLocationInformation();
  }, [currentBusinessID]);

  const onSaveChanges = () => {
    let averagePrice = 0;
    let totalPrice = 0;
    let numItems = 0;
    let overAllRating = 0;

    Object.keys(menuList).forEach((element) => {
      totalPrice += menuList[element].prices.reduce((a, b) => a + b, 0);
      numItems += menuList[element].prices.length;
    });

    averagePrice = totalPrice / numItems;

    if (
      fileImage.file1.length > 0 ||
      fileImage.file2.length > 0 ||
      fileImage.file3.length > 0 ||
      fileImage.file4.length > 0 ||
      locationInformation.profileImages !== undefined
    ) {
      let profileImages = ["", "", "", ""];
      if (locationInformation.profileImages !== undefined) {
        profileImages = locationInformation.profileImages;
      }

      const uploadPromises = profileImages.map(async (x, index) => {
        const file = `file${index + 1}`;

        // **Handle Image Deletion**
        if (fileImage[file] === "" && profileImages[index] !== "") {
          profileImages[index] = ""; // Remove image URL
          return { index, downloadURL: "" }; // Store deletion
        }

        // **Handle New Upload**
        if (fileImage[file] !== x && fileName[file] instanceof File) {
          const uniqueKey = uuidv4();
          const storageRef = ref(storage, `images/${uniqueKey}`);

          try {
            const snapshot = await uploadBytes(storageRef, fileName[file]);
            console.log("Uploaded a blob or file!");

            const downloadURL = await getDownloadURL(snapshot.ref);
            console.log("File available at", downloadURL);

            return { index, downloadURL };
          } catch (error) {
            console.error("Upload error:", error);
            return null;
          }
        }
        return null;
      });

      Promise.all(uploadPromises).then((results) => {
        const successfulUploads = results.filter((result) => result !== null);

        if (successfulUploads.length > 0) {
          successfulUploads.forEach(({ index, downloadURL }) => {
            profileImages[index] = downloadURL; // Update Firestore array
          });

          const collectionRef = doc(db, "lokasjoner", edit);
          updateDoc(collectionRef, { profileImages })
            .then(() => {
              console.log("Document successfully updated!");
            })
            .catch((error) => {
              console.error("Error updating document: ", error);
            });
        }
      });
    }

    // Save main profileImage

    if (
      newProfileImage !== locationInformation.mainProfileImage &&
      newProfileFile
    ) {
      const uniqueKey = uuidv4();
      const storage = getStorage();
      const storageRef = ref(storage, `images/${uniqueKey}`);

      uploadBytes(storageRef, newProfileFile).then((snapshot) => {
        console.log("Uploaded a blob or file!");

        // Get the download URL
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);

          // Save the download URL to Firestore
          const collectionRef = doc(db, "lokasjoner", edit);
          updateDoc(collectionRef, { mainProfileImage: downloadURL })
            .then((docRef) => {
              console.log("Document written with ID: ", docRef.id);
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
            });
        });
      });
    }

    console.log(regularOpeningHours);
    console.log(locationInformation.mainProfileImage === newProfileImage);
    console.log(fileName);

    // Save Tripadvisor
    if (tripadvisorLink && typeof tripadvisorLink === "string") {
      const match = tripadvisorLink.match(/-d(\d+)-/);

      if (match) {
        const id = match[1];
        fetch(
          `https://us-central1-menuapp-4c413.cloudfunctions.net/tripadvisor/${id}`
        )
          .then((res) => res.json())
          .then((data) => {
            // businessDetailsData
            // reviewData
            // Update last tripadvisorUpdateDate
            // Update businessDetailsData.rating
            // Save new collection for reviews with the last 5
            // + todays date
            console.log(data);

            const uniqueKey = uuidv4();
            const docRef = doc(db, "lokasjoner", edit);

            // Reference the subcollection
            const subcollectionRef = collection(docRef, "tripAdvisorRatings");
            const dayDocumentRef = doc(subcollectionRef, uniqueKey);

            updateDoc(docRef, {
              tripadvisorRating: parseFloat(data.businessDetailsData.rating),
              tripadvisorLastUpdate: new Date(),
              tripadvisorId: id,
              tripadvisorNumReviews: parseFloat(
                data.businessDetailsData.num_reviews
              ),
              tripadvisorLink: tripadvisorLink,
              tripadvisorRatingImageLink:
                data.businessDetailsData.rating_image_url,
            });
            overAllRating += parseFloat(data.businessDetailsData.rating);
            setDoc(dayDocumentRef, {
              reviews: data.reviewData.data,
              date: new Date(),
            })
              .then(() => {
                console.log(`${uniqueKey} hours successfully updated!`);
              })
              .catch((error) => {
                console.error(`Error updating ${uniqueKey} hours:`, error);
              });
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.log("ID not found in the URL.");
      }
    } else {
      console.log("tripadvisorLink is not a valid string.");
    }

    // // Save Yelp
    var baseUrl = "https://www.yelp.com/biz/";
    if (yelpLink && typeof yelpLink === "string") {
      var filteredUrl = yelpLink.replace(baseUrl, "");

      fetch(
        `https://us-central1-menuapp-4c413.cloudfunctions.net/tripadvisor/yelpDetails/${filteredUrl}`
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          const uniqueKey = uuidv4();
          const docRef = doc(db, "lokasjoner", edit);

          // Reference the subcollection
          const subcollectionRef = collection(docRef, "yelpRatings");
          const dayDocumentRef = doc(subcollectionRef, uniqueKey);

          updateDoc(docRef, {
            yelpRating: parseFloat(data.businessDetailsData.rating),
            yelpLastUpdate: new Date(),
            yelpId: data.businessDetailsData.id,
            yelpLink: yelpLink,
            yelpNumReviews: data.businessDetailsData.review_count,
          });
          overAllRating += parseFloat(data.businessDetailsData.rating);
          setDoc(dayDocumentRef, {
            reviews: data.reviewData.reviews,
            date: new Date(),
          })
            .then(() => {
              console.log(`${uniqueKey} hours successfully updated!`);
            })
            .catch((error) => {
              console.error(`Error updating ${uniqueKey} hours:`, error);
            });
        });
    } else {
      console.log("yelp is not a valid string.");
      // Håndtering når tripadvisorLink ikke er en gyldig streng
    }

    // Save regular openinghours

    if (
      regularOpeningHours.mon !== null ||
      regularOpeningHours.tue !== null ||
      regularOpeningHours.wed !== null ||
      regularOpeningHours.thu !== null ||
      regularOpeningHours.fri !== null ||
      regularOpeningHours.sat !== null ||
      regularOpeningHours.sun !== null
    ) {
      const parentDocRef = doc(db, "lokasjoner", edit);

      // Reference the subcollection
      const subcollectionRef = collection(parentDocRef, "regularOpeningHours");

      // Loop through the days and store opening hours
      for (const dayOfWeek in regularOpeningHours) {
        if (Object.hasOwnProperty.call(regularOpeningHours, dayOfWeek)) {
          const openingHour = regularOpeningHours[dayOfWeek];
          const closingHour = regularClosingHours[dayOfWeek];

          // Reference the document for the current day of the week
          const dayDocumentRef = doc(subcollectionRef, dayOfWeek);

          // Set the opening and closing hours
          setDoc(dayDocumentRef, {
            open: openingHour,
            close: closingHour,
          })
            .then(() => {
              console.log(`${dayOfWeek} hours successfully updated!`);
            })
            .catch((error) => {
              console.error(`Error updating ${dayOfWeek} hours:`, error);
            });
        }
      }
    }

    console.log(googleBusinessDetails);

    if (googleBusinessDetails.rating !== undefined) {
      overAllRating += parseFloat(googleBusinessDetails.rating);
    }

    const bookingAvailable =
      textInformation.bookingLink.length > 0 ||
      textInformation.phoneLink.length > 0
        ? true
        : false;

    console.log(menuList);
    console.log(menuListEnglish);

    const aboutBusiness = textInformation.description || "";
    const aboutReserve = "Reserver bord på telefon eller på nettsiden vår!";
    const googleNumReviews = googleBusinessDetails.user_ratings_total || 0;
    const googleRating = googleBusinessDetails.rating || 0;
    const googleId = googleBusinessDetails.place_id || "";
    const googleLink = googleBusinessDetails.url || "";
    const lang = "nb_NO";
    const currency = "nok";
    const locationName = textInformation.name || "";
    const aboutBusinessLang = {
      en_US: textInformation.description_enUS || "",
      nb_NO: textInformation.description || "",
    };
    const menuListLang = {
      en_US: menuListEnglish || [],
      nb_NO: menuList || [],
    };
    const locationTagsLang = {
      en_US: textInformation.locationTags_enUS || [],
      nb_NO: textInformation.locationTags || [],
    };
    const menus = menuList || [];
    const phoneNumber =
      textInformation.phoneLink !== undefined ? textInformation.phoneLink : "";
    const tags = textInformation.tags || [];
    const tagsLang = {
      en_US: textInformation.tags_enUS || [],
      nb_NO: textInformation.tags || [],
    };
    const website =
      textInformation.websiteLink !== undefined
        ? textInformation.websiteLink
        : "";
    const bookingLink =
      textInformation.bookingLink !== undefined
        ? textInformation.bookingLink
        : "";
    const adress =
      textInformation.adress !== undefined ? textInformation.adress : "";
    const locationTags = textInformation.locationTags || [];

    console.log("aboutBusiness:", aboutBusiness);
    console.log("aboutReserve:", aboutReserve);
    console.log("averagePrice:", averagePrice);
    console.log("googleNumReviews:", googleNumReviews);
    console.log("googleRating:", googleRating);
    console.log("googleId:", googleId);
    console.log("googleLink:", googleLink);
    console.log("overAllRating:", overAllRating);
    console.log("lang:", lang);
    console.log("currency:", currency);
    console.log("locationName:", locationName);
    console.log("aboutBusinessLang:", aboutBusinessLang);
    console.log("menuListLang:", menuListLang);
    console.log("locationTagsLang:", locationTagsLang);
    console.log("menus:", menus);
    console.log("phoneNumber:", phoneNumber);
    console.log("tags:", tags);
    console.log("tagsLang:", tagsLang);
    console.log("website:", website);
    console.log("bookingLink:", bookingLink);
    console.log("adress:", adress);
    console.log("bookingAvailable:", bookingAvailable);
    console.log("locationTags:", locationTags);

    // Updating the rest of the fields
    const dbCollection = doc(db, "lokasjoner", edit);
    updateDoc(dbCollection, {
      aboutBusiness,
      aboutReserve,
      averagePrice,
      googleNumReviews,
      googleRating,
      googleId,
      googleLink,
      overAllRating,
      lang,
      currency,
      locationName,
      aboutBusinessLang,
      menuListLang,
      locationTagsLang,
      menus,
      phoneNumber,
      tags,
      tagsLang,
      website,
      bookingLink,
      adress,
      bookingAvailable,
      locationTags,
    })
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });

    setSavedChanges(true);
  };

  const onActivateLocation = async () => {
    const dbCollection = doc(db, "lokasjoner", edit);
    const businessCollection = doc(db, "businesses", currentBusinessID);
    const businessInformation = await getDoc(businessCollection);
    const stripeID = businessInformation.data().stripeID;
    const activeSub = businessInformation.data().activeSub;

    if (activeSub) {
      const subscription = await stripe.subscriptions.list({
        customer: stripeID,
        status: "active", // You can specify status as 'active', 'past_due', etc.
      });

      if (subscription.data[0] !== undefined) {
        const numActive = subscription.data[0].quantity;
        const updateSubscription = await stripe.subscriptions.update(
          subscription.data[0].id,
          {
            quantity: numActive + 1,
          }
        );
      }
      updateDoc(dbCollection, {
        activeSub: true,
      });
    }

    updateDoc(dbCollection, {
      active: true,
    });
    setLocationinformation({ ...locationInformation, active: true });
    setOpenActivateModal(false);
  };

  const onDeactivateLocation = async () => {
    const dbCollection = doc(db, "lokasjoner", edit);
    const businessCollection = doc(db, "businesses", currentBusinessID);
    const businessInformation = await getDoc(businessCollection);
    const stripeID = businessInformation.data().stripeID;
    const activeSub = businessInformation.data().activeSub;

    if (activeSub) {
      const subscription = await stripe.subscriptions.list({
        customer: stripeID,
        status: "active", // You can specify status as 'active', 'past_due', etc.
      });

      if (subscription.data[0] !== undefined) {
        const numActive = subscription.data[0].quantity;
        const updateSubscription = await stripe.subscriptions.update(
          subscription.data[0].id,
          {
            quantity: numActive - 1,
          }
        );
      }
    }

    updateDoc(dbCollection, {
      active: false,
    });
    setLocationinformation({ ...locationInformation, active: false });
    setOpenActivateModal(false);
  };

  return locationInformation !== null ? (
    !businessOwner ? (
      <>Access Denied!</>
    ) : (
      <>
        <div className='editLocationPage'>
          <h1>
            Rediger{" "}
            <label className='locationNameText'>
              {locationInformation.locationName}
            </label>
          </h1>
          <div className='aboutLocationEditting'>
            <label>Location adress</label>
            <label>
              Type lokasjon:{" "}
              <label
                style={{
                  color:
                    locationInformation.type === "restaurant"
                      ? "#5d3160"
                      : locationInformation.type === "activity"
                      ? "#52c78c"
                      : "#4550e2",
                }}>
                {type[locationInformation.type]}
              </label>
            </label>
          </div>
          <div>
            <label
              style={{
                color: locationInformation.active ? "#52c78c" : "#f9615b",
                fontWeight: "500",
              }}>
              {locationInformation.active ? "Aktiv" : "Ikke aktiv"}
            </label>
            <button
              className='universalButton'
              style={{ marginLeft: "10px" }}
              onClick={() => {
                setOpenActivateModal(true);
              }}>
              {locationInformation.active ? "Deaktiver" : "Aktiver"}
            </button>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <button
                onClick={() => {
                  setSelectedEditMenu("about");
                }}
                className='editMenuButtons'
                style={
                  selectedEditMenu === "about"
                    ? {
                        color: "#f9615b",
                        backgroundColor: "white",
                        border: "white",
                      }
                    : {}
                }>
                Om
              </button>
              <button
                onClick={() => {
                  setSelectedEditMenu("menu");
                }}
                className='editMenuButtons'
                style={
                  selectedEditMenu === "menu"
                    ? {
                        color: "#f9615b",
                        backgroundColor: "white",
                        border: "white",
                      }
                    : {}
                }>
                {locationInformation.type === "restaurant"
                  ? "Meny"
                  : "Aktiviteter"}
              </button>
              {editLanguage !== "english" ? (
                <button
                  onClick={() => {
                    setSelectedEditMenu("reviews");
                  }}
                  className='editMenuButtons'
                  style={
                    selectedEditMenu === "reviews"
                      ? {
                          color: "#f9615b",
                          backgroundColor: "white",
                          border: "white",
                        }
                      : {}
                  }>
                  Omtaler
                </button>
              ) : (
                <></>
              )}
            </div>
            <div>
              <button
                onClick={() => {
                  if (editLanguage !== "english") {
                    setEditLanguage("english");
                  } else {
                    setEditLanguage("norwegian");
                  }
                }}
                className='editMenuButtons'
                style={
                  editLanguage === "english"
                    ? {
                        color: "#f9615b",
                        backgroundColor: "white",
                        border: "white",
                      }
                    : {}
                }>
                Engelsk
              </button>
            </div>
          </div>
          <div
            className='box'
            style={{
              borderTopLeftRadius: 0,
              padding: 25,
              paddingRight: 35,
              marginBottom: "50px",
            }}>
            {selectedEditMenu === "about" ? (
              <EditAbout
                regularOpeningHours={regularOpeningHours}
                setRegularOpeningHours={setRegularOpeningHours}
                setRegularClosingHours={setRegularClosingHours}
                regularClosingHours={regularClosingHours}
                newCustomOpeningHours={newCustomOpeningHours}
                setNewCustomOpeningHours={setNewCustomOpeningHours}
                locationInfo={locationInformation}
                newProfileImage={newProfileImage}
                setNewProfileImage={setNewProfileImage}
                fileImage={fileImage}
                setFileImage={setFileImage}
                fileName={fileName}
                setFileName={setFileName}
                textInformation={textInformation}
                setTextInformation={setTextInformation}
                newProfileFile={newProfileFile}
                setNewProfileFile={setNewProfileFile}
                newProfileImagePreview={newProfileImagePreview}
                setNewProfileImagePreview={setNewProfileImagePreview}
                editLanguage={editLanguage}
              />
            ) : selectedEditMenu === "menu" ? (
              <EditMenu
                locationInfo={locationInformation}
                newMenu={newMenu}
                setNewMenu={setNewMenu}
                newMenuEnglish={newMenuEnglish}
                setNewMenuEnglish={setNewMenuEnglish}
                lokasjonstype={locationInformation.type}
                menuList={menuList}
                setMenuList={setMenuList}
                menuListEnglish={menuListEnglish}
                setMenuListEnglish={setMenuListEnglish}
                editLanguage={editLanguage}
              />
            ) : (
              <EditReviews
                locationInfo={locationInformation}
                googleBusinessDetails={googleBusinessDetails}
                setGoogleBusinessDetails={setGoogleBusinessDetails}
                tripadvisorLink={tripadvisorLink}
                setTripadvisorLink={setTripadvisorLink}
                yelpLink={yelpLink}
                setYelpLink={setYelpLink}
              />
            )}
            {newMenu ? (
              <></>
            ) : (
              <>
                <button
                  onClick={() => {
                    setOpenSaveChangesModal(true);
                  }}
                  className='universalButtonRed'
                  style={{
                    width: "150px",
                    marginTop: "25px",
                    marginRight: "10px",
                  }}>
                  Lagre endringer
                </button>
                <button
                  onClick={() => {
                    setOpenCancelChangesModal(true);
                  }}
                  className='universalButton'>
                  Avbryt
                </button>
              </>
            )}
          </div>
        </div>
        <Modal
          isOpen={openActivateModal}
          onRequestClose={() => {
            setOpenActivateModal(false);
          }}
          className='modalStyle'
          style={{
            content: {
              width: "17%",
            },
          }}>
          {locationInformation.active ? (
            <div className='editActiveModal'>
              <label className='editPageLabel'>
                Er du sikker på at du vil deaktivere denne lokasjonen?
              </label>
              <div className='editActiveModalButtonContainer'>
                <button
                  onClick={() => {
                    onDeactivateLocation();
                  }}
                  className='universalButton'
                  style={{
                    width: "70%",
                    margin: 0,
                  }}>
                  Ja, deaktiver
                </button>
                <button
                  onClick={() => {
                    setOpenActivateModal(false);
                  }}
                  className='universalButtonRed'
                  style={{
                    width: "70%",
                    marginTop: 10,
                    marginBottom: 0,
                  }}>
                  Nei, ta meg tilbake
                </button>
              </div>
            </div>
          ) : (
            <div className='editActiveModal'>
              <label className='editPageLabel'>
                Er du sikker på at du vil aktivere denne lokasjonen?
              </label>
              <div className='editActiveModalButtonContainer'>
                <button
                  onClick={() => {
                    onActivateLocation();
                  }}
                  className='universalButtonRed'
                  style={{
                    width: "70%",
                    margin: 0,
                  }}>
                  Ja, aktiver
                </button>
                <button
                  onClick={() => {
                    setOpenActivateModal(false);
                  }}
                  className='universalButton'
                  style={{
                    width: "70%",
                    marginTop: 10,
                    marginBottom: 0,
                  }}>
                  Nei, ta meg tilbake
                </button>
              </div>
            </div>
          )}
        </Modal>
        {/* Save Changes Modal */}
        <Modal
          isOpen={openSaveChangesModal}
          onRequestClose={() => (
            setOpenSaveChangesModal(false), setSavedChanges(false)
          )}
          className='modalStyle'
          style={{
            content: {
              width: "20%",
            },
          }}>
          <div className='editActiveModal'>
            <label className='editPageLabel'>
              Lagerer og publiserer endringer! Er du sikker på at du vil lagre
              og publisere endringene for offentligheten?
            </label>
            <div className='editActiveModalButtonContainer'>
              <div
                style={{
                  display: savedChanges ? "block" : "none",
                  color: "#52c78c",
                  marginBottom: "5px",
                }}>
                Endringer lagret!
              </div>
              <button
                onClick={onSaveChanges}
                className='universalButtonRed'
                style={{
                  width: "240px",
                  margin: 0,
                }}>
                Ja, lagre og publiser endringer
              </button>
              <button
                onClick={() => (
                  setOpenSaveChangesModal(false), setSavedChanges(false)
                )}
                className='universalButton'
                style={{
                  width: "70%",
                  marginTop: 10,
                  marginBottom: 0,
                }}>
                Nei, ta meg tilbake
              </button>
            </div>
          </div>
        </Modal>
        {/* Cancel Changes Modal */}
        <Modal
          isOpen={openCancelChangesModal}
          onRequestClose={() => {
            setOpenCancelChangesModal(false);
          }}
          className='modalStyle'
          style={{
            content: {
              width: "20%",
            },
          }}>
          <div className='editActiveModal'>
            <label className='editPageLabel'>
              Kansellerer endringer! Er du sikker på at du vil slette endringene
              som har blitt gjort og gå tilbake?
            </label>
            <div className='editActiveModalButtonContainer'>
              <button
                onClick={() => {
                  setOpenCancelChangesModal(false);
                  navigate("/MyBusiness/myLocations");
                }}
                className='universalButton'
                style={{
                  width: "70%",
                  margin: 0,
                }}>
                Ja, slett endringer
              </button>
              <button
                onClick={() => {
                  setOpenCancelChangesModal(false);
                }}
                className='universalButtonRed'
                style={{
                  width: "70%",
                  marginTop: 10,
                  marginBottom: 0,
                }}>
                Nei, ta meg tilbake
              </button>
            </div>
          </div>
        </Modal>
      </>
    )
  ) : (
    <></>
  );
};

export default EditMyLocation;
