import React, { useState, useEffect } from "react";
import "../css/homepage.css";
import MapliLogoWhite from "../assets/images/Hvit_Logo.svg";
import { getAuth } from "firebase/auth";
import Profil_Ikon_White from "../assets/images/Profil_Ikon_white.svg";
import { firestore } from "../firebase";

const auth = getAuth();

const Header = ({ faq }) => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged(function (user) {
      if (user) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    });
  }, [auth]);

  return (
    <header className='header'>
      <div className='headerLeft'>
        <a href='/'>
          <img src={MapliLogoWhite} height='40px' />
        </a>
      </div>
      <div className='headerRight'>
        {/* <a href='/prices' className='headerPricesWhite'>
          Priser
        </a> */}
        <a href='/faq' className='headerPricesWhite'>
          FAQ
        </a>
        {loggedIn ? (
          <a href='/MyBusiness' className='headerPricesWhite'>
            <img src={Profil_Ikon_White} style={{ height: "20px" }} />
          </a>
        ) : (
          <></>
        )}
        <label>
          <span className='headerPricesWhite'>|</span>
        </label>
        {faq !== undefined && faq ? (
          <a href='/login'>
            <p className='businessPageLoginButton'>For bedrifter</p>
          </a>
        ) : (
          <p className='businessPageLoginText'>For bedrifter</p>
        )}
      </div>
    </header>
  );
};

export default Header;
