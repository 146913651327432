import React, { useEffect, useState } from "react";
import "../../css/statsPage.css"; // Legg til CSS-fil for styling
import { doc, getDoc, updateDoc, setDoc, collection } from "firebase/firestore";
import { firestore, GeoFirestore } from "../../firebase";

const db = firestore;

function StatsPage({ currentBusinessID }) {
  const [profiles, setProfiles] = useState([]);
  const [profilesData, setProfilesData] = useState([]);
  const [business, setBusiness] = useState(null);
  const [totalViews, setTotalViews] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null); // Added selectedProfile state
  const [loading, setLoading] = useState(true);
  const [locationIds, setLocationIds] = useState([]);
  const [locationNames, setLocationNames] = useState([]);

  useEffect(() => {
    const effectFunction = async () => {
      const userInfo = doc(db, "businesses", currentBusinessID);
      const userLocationList = await getDoc(userInfo);
      setBusiness(userLocationList.data());

      if (
        userLocationList.data().menus !== undefined &&
        userLocationList.data().menus.length > 0
      ) {
        fetchData(userLocationList.data().menus);
      }
    };

    // Example of a basic format check, e.g., checking if the ID is a non-empty string
    const isValidID =
      currentBusinessID &&
      typeof currentBusinessID === "string" &&
      currentBusinessID.length > 0;

    if (isValidID) {
      effectFunction();
    } else {
      console.log("Invalid business ID. Please check the ID or sign in.");
    }
  }, [currentBusinessID]);

  const fetchData = async (listData) => {
    const fetchedData = await Promise.all(
      listData.map(async (value) => {
        const docRef = doc(db, "lokasjoner", value);
        const docSnapshot = await getDoc(docRef);
        return docSnapshot;
      })
    );

    setLocationIds(listData);
    let locationNamesArray = [];
    fetchedData.map((lokasjonsInformasjon, rowIndex) => {
      locationNamesArray.push(lokasjonsInformasjon);
    });
    setLocationNames(locationNamesArray);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        fetch(
          `https://us-central1-menuapp-4c413.cloudfunctions.net/tripadvisor/api/viewCounts/${locationIds.join(
            ","
          )}`
        )
          .then((res) => res.json())
          .then((data) => {
            setProfilesData(data.data);
            setProfiles(locationNames);
            setTotalViews(data.totalViews);
          });
      } catch (error) {
        console.error("Error fetching profile views:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [locationIds]);

  if (loading) return <p>Laster...</p>;

  return (
    <div className='stats-page'>
      <h2>Profilvisninger</h2>
      <div className='profile-selector'>
        <select
          onChange={(e) => setSelectedProfile(e.target.value)}
          className='profile-dropdown'>
          <option value='total'>Totalt antall visninger</option>
          {profiles.map((profile) => (
            <option key={profile.id} value={profile.id}>
              {profile.data().locationName}
            </option>
          ))}
        </select>
      </div>

      <table className='stats-table'>
        <thead>
          <tr>
            <th>Periode</th>
            <th>Antall visninger</th>
          </tr>
        </thead>
        <tbody>
          {selectedProfile === "total" || !selectedProfile ? (
            <>
              <tr>
                <td>Siste 24 timer</td>
                <td>{totalViews?.last24Hours}</td>
              </tr>
              <tr>
                <td>Siste 7 dager</td>
                <td>{totalViews?.last7Days}</td>
              </tr>
              <tr>
                <td>Siste 30 dager</td>
                <td>{totalViews?.last30Days}</td>
              </tr>
              <tr>
                <td>Siste 90 dager</td>
                <td>{totalViews?.last90Days}</td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td>Siste 24 timer</td>
                <td>
                  {
                    profilesData.find(
                      (profile) => profile.location_id === selectedProfile
                    )?.last_24_hours
                  }
                </td>
              </tr>
              <tr>
                <td>Siste 7 dager</td>
                <td>
                  {
                    profilesData.find(
                      (profile) => profile.location_id === selectedProfile
                    )?.last_7_days
                  }
                </td>
              </tr>
              <tr>
                <td>Siste 30 dager</td>
                <td>
                  {
                    profilesData.find(
                      (profile) => profile.location_id === selectedProfile
                    )?.last_30_days
                  }
                </td>
              </tr>
              <tr>
                <td>Siste 90 dager</td>
                <td>
                  {
                    profilesData.find(
                      (profile) => profile.location_id === selectedProfile
                    )?.last_90_days
                  }
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default StatsPage;
